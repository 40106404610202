<template>
    <div class="toolbar_main">
        <div class="osahan-breadcrumb">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/"><i class="icofont icofont-ui-home"></i> Inicio</a></li>
                            <li class="breadcrumb-item active">Seguimiento de pedido</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="shopping_cart_page">
            <div class="container justify-content-between py-2 py-lg-3">
                <div class="row text-center text-lg-start">
                    <div class="col-lg-12 col-md-8 col-sm-7">
                        <div class="widget">
                            <h3 class="h3 text-black mb-0">
                                Orden de seguimiento:
                                <span class="" style="display: inline-block;">
                                    <div class="input-group mb-3">
                                        <input type="text" v-model="nro_seguimiento" ref="nro_seguimiento" class="form-control" placeholder="Nro orden">
                                        <button class="btn btn-danger cursor-pointer" type="button" @click="buscarOrden">Buscar</button>
                                    </div>
                                </span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="detalle.length<1" class="container py-2 mb-0 mb-md-1">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="alert alert-secondary py-5 px-lg-5 text-center mb-3">
                        <i class="h2 ci-announcement mb-4" :class="[!buscar?'text-info':'text-primary']"></i>
                        <h1 v-if="buscar && nro_seguimiento!=''" class="h1 pb-2 color_claro">Resultado de búsqueda</h1>
                        <h1 v-else class="h1 pb-2 color_claro">Léeme</h1>

                        <p v-if="buscar && nro_seguimiento!=''" class="fs-md pb-1 mb-0">
                            No se obtuvo resultado de la busca con el orden de servicio <b>{{nro_seguimiento}}</b>, para mas información, por favor comuniquese con al telefono <strong>{{empresa.telefonos}}</strong>
                            o al correo electronico <strong>{{empresa.email}}</strong> para mayor informacion.
                        </p>
                        <p v-else class="mb-0">
                            Ingrese el Número de orden que se le proporcionó al momento de realizar la compra de su producto y haga clic en el botón <span class="text-primary">Buscar</span>
                        </p>

                        <p class="mb-0">El equipo de <strong>{{empresa.abreviatura}}</strong> le agradece su comprensión y preferencia.</p>
                    </div>
                </div>
            </div>
        </div>

        <section v-if="detalle.length>0" class="shopping_cart_page">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-8 col-sm-7">
                        <div class="widget">
                            <div class="section-header">
                                <h5 class="heading-design-h5">Estado de orden</h5>
                            </div>

                            <div class="status-main">
                                <div class="row">
                                    <div class="container px-1 px-md-4 py-0 mx-auto">
                                        <div class="card card-x" >
                                            <div class="row d-flex justify-content-between px-3 top">
                                                <div class="d-flex">
                                                    <h5>Nro Orden <span class="text-primary font-weight-bold">#{{nro_seguimiento}}</span></h5>
                                                </div>
                                            </div>

                                            <!-- Add class 'active' to progress -->
                                            <div class="row d-flex justify-content-center">
                                                <div class="col-12">
                                                    <ul id="progressbar" class="text-center">
                                                        <li v-for="(val, key) in etapas" :key="key" :class="['step0', (val.completado==1)?'active':'']"></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="row justify-content-between top">
                                                <div v-for="(val, key) in etapas" :key="key" class="row d-flex icon-content">
                                                    <i :class="['fa', val.icono, 'fa-2x', 'mr-2']"></i>
                                                    <div class="d-flex flex-column"  style="justify-content: center;" >
                                                        <p class="font-weight-bold">{{val.etapa}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12 col-md-12"><hr></div>

                                    <div class="col-lg-4 col-md-4">
                                        <div class="statustop">
                                            <p><strong>Estado:</strong> {{operacion.etapa_operacion??''}} </p>
                                            <p><strong>Fecha de pedido:</strong> {{operacion.fecha_registro_es??''}}</p>               
                                            <br>              
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-8">
                                        <div class="statustop">
                                            <p><strong>Dirección de cliente: </strong> {{operacion.direccion_cliente??''}}, {{operacion.ubigeo??''}} </p>
                                            <p><strong>Cliente: </strong> {{operacion.cliente??''}}  </p>
                                            <br>              
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="card">
                                            <div class="card-header pt-1 pb-1">
                                                Detalle del pedido
                                            </div>

                                            <div class="card-block padding-none">
                                                <table class="table cart_summary table-responsive pt-1 pb-1" style="display: table;">
                                                    <thead>
                                                        <tr>
                                                            <th class="cart_product  pt-1 pb-1 text-center">Producto</th>
                                                            <th class=" pt-1 pb-1" >Descripcion</th>
                                                            <th class=" pt-1 pb-1" >Cantidad</th>
                                                            <th class=" pt-1 pb-1" >Unit price</th>
                                                            <th class=" pt-1 pb-1" >Total</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(val, key) in detalle" :key="key">
                                                            <td class="cart_product pt-1 pb-1">
                                                                <a :href="'/producto/'+sucursal_seleccionada+'/'+val.cod_producto">
                                                                    <img class="img-fluid" :src="val.url_imagen" :alt="val.descripcion_producto">
                                                                </a>
                                                            </td>
                                                            <td class="cart_description">
                                                                <p class="product-name">
                                                                    <a :href="'/producto/'+sucursal_seleccionada+'/'+val.cod_producto">{{val.descripcion_producto}}</a>
                                                                </p>
                                                                <small class="mr-4">SKU {{val.cod_articulo}}</small>
                                                                <small v-if="val.series">IMEI {{val.series}}</small>
                                                            </td>
                                                            <td class="availability in-stock">{{val.cantidad}} UND</td>
                                                            <td class="price"><span>S/ {{number_format(val.precio_venta, 2, '.', ',')}}</span></td>
                                                            <td class="price"><span>S/ {{number_format(val.precio_venta*val.cantidad, 2, '.', ',')}}</span></td>
                                                        </tr>
                                                    </tbody>
                                                    
                                                    <tfoot>
                                                        <tr>
                                                            <td colspan="4" class="pt-1 pb-1"><strong>Total</strong></td>
                                                            <td colspan="1" class="pt-1 pb-1"><strong>S/ {{number_format(operacion.total_importe, 2, '.', ',')}}</strong></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';

    export default {
        name: 'seguimiento_pedido',
        computed:{
            ...mapState(['empresa', 'mi_cuenta', 'sucursal_seleccionada']),
        },
        created(){
            this.loadObjSucursalSeleccionada();
            this.loadObjPerfilUsuario();
        },
        mounted(){
            document.title = "Seguimiento pedido | "+((this.empresa.abreviatura)?this.empresa.abreviatura:"-");
        },
        data(){
            return{
                nro_seguimiento:"",
                nro_seguimiento_busqueda:"",
                buscar:false,
                operacion:{
                    etapa_operacion:"",
                    fecha_registro_es:"",
                    cliente:"",
                    direccion_cliente:"",
                    ubigeo:"",
                    total_importe:0,
                },
                etapas:{},
                detalle:[],
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjPerfilUsuario', 'loadObjSucursalSeleccionada']),
            buscarOrden(){
                if(!this.nro_seguimiento){
                    toastr.warning('Ingrese el número de orden de seguimiento', 'Mensaje', { showDuration: 300, hideDuration: 12000, progressBar: true, closeButton:  true });
                    this.$refs.nro_seguimiento.focus();
                    return;
                }
                    

                this.buscar                   = true;
                this.operacion                = {};
                this.detalle                  = [];
                this.nro_seguimiento_busqueda = this.nro_seguimiento;

                var formData = new FormData();
                formData.append("nro_seguimiento", this.nro_seguimiento);

                this.setLoading(true);
                this.$http.post('tiendaVirtual/seguimiento_orden', formData).then((res)=>{
                    if(res.data.venta){
                        this.operacion  = res.data.venta;
                        this.detalle    = res.data.venta.detalle??[];
                        this.etapas     = res.data.etapas;
                        this.buscar     = false;
                    }else{
                        this.operacion  = {};
                        this.detalle    = [];
                        this.etapas     = [];

                        setTimeout(()=>{
                            this.buscar   = false;
                        }, 6000);
                    }
                }).catch(e => {
                    this.buscar   = true;
                    console.log(e.response.status);
                })
                .finally(()=>{
                    this.setLoading(false);
                });
            },
        },
    }
</script>